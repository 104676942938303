import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config = {
  useSystemColorMode: false,
  initialColorMode: "light",
};

const theme = extendTheme({
  config,
  styles: {
    global: props => ({
      "html, body": {
        background: mode("gray.200", "gray.800")(props),
        background: "white",
      },
      ".base": {
        background: mode("white", "gray.700")(props),
      },
    }),
  },
});

export default theme;

import { Heading, VStack } from "@chakra-ui/react";
import usePageTracking from "@constants/usePageTracking";

export default function ComingSoonPage() {
usePageTracking();

return (
    <VStack height="100vh"><Heading margin="auto" userSelect="none">Something new and exciting is coming soon...</Heading></VStack>
);
}

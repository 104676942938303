import {
  ChakraProvider,
  ColorModeScript,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ComingSoonPage from "@pages/ComingSoonPage";
import IndexPage from "@pages/IndexPage";
import AboutPage from "@pages/AboutPage";
import ProjectsPage from "@pages/ProjectsPage";
import NotFoundPage from "@pages/NotFoundPage";
import BlogPage from "@pages/blog/BlogPage";
import BlogContentPage from "@pages/blog/BlogContentPage";
import TrackingPage from "@pages/TrackingPage";
import theme from "@constants/theme";

export default function App() {
  return (
    <>
      <ColorModeScript
        initialColorMode={theme.config.initialColorMode}
      />
      <ChakraProvider theme={theme}>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="*" element={<ComingSoonPage />} />
            {1 == 0 ? <><Route path="/" element={<IndexPage />} />
            <Route
              path="/discord"
              element={<TrackingPage redirectTo="/" />}
            />
            <Route
              path="/github"
              element={<TrackingPage redirectTo="/" />}
            />
            <Route
              path="/twitter"
              element={<TrackingPage redirectTo="/" />}
            />
            <Route path="/about" element={<AboutPage />} />
            <Route
              path="/projects"
              element={<ProjectsPage />}
            />
            <Route path="/blog" element={<BlogPage />} />
            <Route
              path="/blog/:blog_name"
              element={<BlogContentPage />}
            />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} /></> : null}
          </Routes>
        </Router>
      </ChakraProvider>
    </>
  );
}

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const GA_TRACKING_CODE = "G-3259N0G02B";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_CODE);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;
